<template>
    <container style="max-width: 1000px;">
        <v-row>
            <v-col>
                <v-card outlined>
                    <v-toolbar elevation="0">
                        <v-btn @click="$router.go(-1)" icon>
                            <v-icon>mdi-arrow-left</v-icon>
                        </v-btn>
                        <v-toolbar-title class="primary--text">
                            {{ $capitalize($tc("model.form_lead_new_lead_title")) }}
                        </v-toolbar-title>
                    </v-toolbar>
                </v-card>
            </v-col>
        </v-row>

        <LeadForm @submit="createLead" @cancel="$router.go(-1)" ref="leadForm" />

        <v-dialog v-model="loading" width="300" persistent>
            <v-card color="primary" dark>
                <v-card-text>
                    {{loadingProgressionText}}
                    <v-progress-linear
                        :value="loadingProgressionValue"
                        color="white"
                        class="mb-0"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </container>
</template>

<script>
import LeadForm from "@/components/Lead/Form/LeadForm";
import LeadMixin from "@/mixins/LeadMixin";

export default {
    components: {
        LeadForm,
    },

    mixins: [ LeadMixin ],

    async created() {
        const insurerId = this.$route.params.insurer_id;
        
        this.$store.commit('setLoading', {
            loading: true
        });
        
        try {
            await this.$store.dispatch('lead_form/loadCreateLeadMetadata', { insurerId });
        } catch(error) {
            if(error.response.status == 404) {
                this.notFound = true;
            }
        } finally {
            this.$store.commit('setLoading', {
                loading: false
            });
        }
    }
}
</script>

<style>

</style>